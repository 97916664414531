<template>
  <div id="claims" class="container-xxl">
    <div class="row">
      <ClaimElement
        icon="fa-clock"
        :label="$t('claim.opening.title')"
        :text1="$t('claim.opening.days')"
        :text2="$t('claim.opening.time')"
      />
      <ClaimElement
        icon="fa-phone"
        :label="$t('claim.appointment.title')"
        :text1="$t('claim.appointment.line1')"
        :text2="$t('claim.appointment.line2')"
        link="tel:+41444821437"
      />
      <ClaimElement
        icon="fa-map-marker-alt"
        :label="$t('claim.route.title')"
        :text1="$t('claim.route.line1')"
        :text2="$t('claim.route.line2')"
        link="https://www.google.ch/maps/dir//Sihlweidstrasse+1,+8041+Z%C3%BCrich/"
      />
      <ClaimElement
        v-if="isOpen()"
        icon="fa-medkit"
        :label="$t('claim.emergency.title')"
        :text1="$t('claim.emergency.line1')"
        :text2="$t('claim.emergency.line2')"
        link="tel:+41444821437"
      />
      <ClaimElement
        v-else
        icon="fa-medkit"
        :label="$t('claim.emergency2.title')"
        :text1="$t('claim.emergency2.line1')"
        :text2="$t('claim.emergency2.line2')"
        link="tel:0800 33 66 55"
      />
    </div>
  </div>

  <a id="view"></a>
  <nav id="navi" class="navbar navbar-expand-md navbar-light sticky-top bg-light">
    <div class="container-xxl">
      <a class="navbar-brand" href="/" :title="$t('nav.label')"
        ><img src="logo.png" :alt="$t('nav.label')"
      /></a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarCollapse"
        aria-controls="navbarCollapse"
        aria-expanded="false"
        aria-label="Toggle navigation"
        onclick="document.querySelector('#navi').scrollIntoView({
     behavior: 'smooth'
})"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarCollapse">
        <ul class="navbar-nav me-auto mb-2 mb-md-0" style="margin-left: 50px">
          <li class="nav-item">
            <router-link to="/" class="nav-link">Home</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/advantages" class="nav-link">{{
              $t("nav.advantages")
            }}</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/services" class="nav-link">{{
              $t("nav.services")
            }}</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/practice" class="nav-link">{{
              $t("nav.practice")
            }}</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/pricing" class="nav-link">{{
              $t("nav.pricing")
            }}</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/about" class="nav-link">{{ $t("nav.me") }}</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/team" class="nav-link">{{ $t("nav.team") }}</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/contact" class="nav-link">{{
              $t("nav.contact")
            }}</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/notfall" class="nav-link">{{
              $t("nav.emergency")
            }}</router-link>
          </li>
        </ul>
        <!--
        <div class="d-none d-lg-block">
          <ul class="navbar-nav me-auto mb-2 mb-md-0">
            <li class="nav-item">
              <span class="nav-link" @click="changeLang('de')">DE</span>
            </li>
            <li class="nav-item">
              <span class="nav-link" @click="changeLang('en')">EN</span>
            </li>
          </ul>
        </div>
        -->
      </div>
    </div>
  </nav>
  <router-view />
</template>

<script>
import ClaimElement from "@/components/ClaimElement.vue";
import moment from "moment";

export default {
  name: "App",
  components: {
    ClaimElement,
  },
  methods: {
    changeLang(lang) {
      this.$i18n.locale = lang;
      localStorage.lang = lang;
    },
    isOpen() {
      moment.locale("de", { trim: false });
      if (
        moment().isoWeekday() < 6 &&
        moment().format("HH:mm") > "07:30" &&
        moment().format("HH:mm") < "16:00"
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style>
.nav-item {
  cursor: pointer;
}

.router-link-active {
  font-weight: 600;
}

#claims {
  min-height: 80px;
  line-height: 80px;
  background-color: #ffffff;
}

#claims a {
  text-decoration: none;
  color: inherit;
}

#claims table {
  border: 0;
  border-collapse: collapse;
  padding: 0;
  margin: 0;
  line-height: normal;
}

#claims table td {
  height: 80px;
}

#claims table td.icon {
  font-size: 36px;
  padding-right: 10px;
  font-weight: 300;
}
</style>
